<template>
  <kinesis-container :duration="300">
    <kinesis-element :strength="3" type="depth_inv">
      <div
        class="card"
        @click="txt(item.id)"
        ref="card"
      >
        <div
          class="overlay"
          :style="{
            background: `linear-gradient(0deg, rgb(142, 49, 49) 20%, 1%, rgba(34, 35, 38, 0) 156%), url(${
              item.images[0].img // Always play the GIF, no hover needed
            }) top center/cover`,
          }"
        ></div>
        <div class="info">
          <kinesis-element :strength="2" type="depth_inv">
            <h3>{{ item.titel }}</h3>
          </kinesis-element>
          <div class="tags">
            <span v-for="tag in item.tags" :key="tag">{{ tag }}</span>
          </div>
          <div class="tags links">
            <a :href="item.demo" v-if="item.demo.length > 1">Demo</a>
            <a :href="item.source" target="_blank">Click For More Info</a>
          </div>
        </div>
      </div>
    </kinesis-element>
  </kinesis-container>
</template>

<script>
import { KinesisContainer, KinesisElement } from 'vue-kinesis';

export default {
  components: { KinesisContainer, KinesisElement },
  data: () => ({
    observer: null, // Initialize observer data
  }),
  props: {
    item: Object,
    txt: Function,
  },
  mounted() {
    this.initIntersectionObserver(); // Initialize observer
  },
  methods: {
    initIntersectionObserver() {
      const options = {
        root: null, // Observe the entire viewport
        threshold: 0.1, // Trigger when 10% of the card is visible
      };

      this.observer = new IntersectionObserver(this.handleIntersect, options);

      // Observe this specific card
      if (this.$refs.card) {
        this.observer.observe(this.$refs.card);
      }
    },
    handleIntersect(entries) {
      entries.forEach((entry) => {
        const imgElement = entry.target.querySelector('.overlay');
        if (entry.isIntersecting) {
          // Card is in view, trigger the GIF
          imgElement.style.background = `linear-gradient(0deg, rgb(142, 49, 49) 0.5%, rgba(34, 35, 38, 0) 30%), url(${this.item.images[0].img}) bottom center/cover`;
          console.log('Visible card:', this.item.titel);
        }
      });
    },
  },
  beforeDestroy() {
    // Disconnect observer when component is destroyed
    if (this.observer) {
      this.observer.disconnect();
    }
  },
};
</script>

<style lang="scss" scoped>
.card:hover .overlay {
  /* Hover effect disabled */
  /* box-shadow: inset 0px -363px 292px -300px #2e2f3180; */
  /* opacity: 1; */
}

.card {
  width: 350px;
  height: 480px;
  background: #212225;
  border-radius: 0.8rem;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  display: block;
}

.card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  z-index: 0;
  border-radius: 0px 0px 2rem 2rem;
}

.card .overlay {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  transform: scale(1.02);
  border-radius: 1rem;
  box-shadow: inset 0px 0px 0px 0px #2e2f31;
  opacity: 0.7;
  z-index: 1;
}


.card .info {
  padding: 0 1.3rem 0.5rem 1.3rem; /* Reduce the bottom padding */
  z-index: 3;
  position: absolute;
  bottom: 0;
  width: 100%;
}









.card h3 {
  font-weight: 500;
  font-size: 1.7rem;
  line-height: 1.4;
  margin-bottom: 0rem;
  margin-top: 0;
}

.card .tags {
  display: flex;
  gap: 0.7rem;
  font-weight: 500;
  font-size: 0.8rem;
  color: var(--txt-med);
}

.card .tags.links a {
  color: white;
  text-decoration: none;
  margin-top: 0.6rem;
}
</style>
